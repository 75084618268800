<template>
  <Dialog
    v-model:visible="mostrarModal"
    :modal="true"
    :style="{ width: '1200px' }"
    header="Productos encontrados"
    class="p-fluid"
    @hide="cancelar"
  >
    <div class="col-12">
      <div class="">
        <DataTable
          :value="data_productos"
          class="p-datatable-sm"
          v-model:expandedRows="expandedRows"
          stripedRows
          showGridlines
          :filters="buscar"
          :rowClass="stock_cero"
          selectionMode="single click"
          @row-dblclick="productos_selected.push($event.data)"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar Productos..."
                />
              </span>
            </div>
          </template>
          <Column style="width: 3rem">
            <template #header>
              <i class="pi pi-check-circle ml-1"></i>
            </template>
            <template #body="slotProps">
              <Checkbox
                class="ml-1"
                v-model="productos_selected"
                :value="slotProps.data"
                :disabled="esStockCero(slotProps.data)"
              />
            </template>
          </Column>
          <Column
            field="codigo_interno"
            header="CÓD. INTERNO"
            style="width: 8rem"
          />
          <Column
            field="codigo_origen"
            header="CÓD. ORIGEN"
            style="width: 8rem"
          />
          <Column
            field="descripcion"
            header="DESCRIPCIÓN/PRODUCTO"
            style="font-weight: bold"
          />
          <Column
            field="caso_uso"
            header="CASO USO/APLICACIÓN"
            style="font-weight: bold"
          />
          <Column
            header="STOCK"
            style="
              font-weight: bold;
              text-align: center;
              font-size: 1.2rem;
              width: 5rem;
            "
          >
            <template #body="slotProps">
              <span v-if="esCompra" :class="claseEstado(slotProps.data)">{{
                slotProps.data.stock.length > 0
                  ? convertirNumeroGermanicFormat(
                      slotProps.data.stock[0].cantidad
                    )
                  : 0
              }}</span>

              <span v-else :class="claseEstado(slotProps.data)">
                {{
                  slotProps.data.stock[0]?.cantidad == undefined
                    ? 0
                    : sinDecimal(slotProps.data.stock[0].cantidad)
                }}
              </span>
            </template>
          </Column>
          <Column
            field="precio_sugerido"
            class="text-1xl text-right"
            :header="esCompra ? 'P.COMPRA' : 'P.VENTA'"
            style="font-weight: bold; text-align: right; width: 5rem"
          >
            <template #body="slotProps">
              <span v-if="esCompra">{{
                convertirNumeroGermanicFormat(slotProps.data.precio_compra)
              }}</span>
              <span v-else>{{
                convertirNumeroGermanicFormat(
                  obtenerPreciosVentaProductos(slotProps.data)
                )
              }}</span>
            </template>
          </Column>
          <Column
            field="unidad_medida_nombre"
            header="U.MEDIDA"
            style="font-weight: bold; text-align: center; width: 5rem"
          />
          <Column field="fabrica_nombre" header="MARCA" />
          <Column
            field="imagen"
            header="IMAGEN"
            v-if="'Producto VerImagen' in auth.user.permissions"
            style="width: 5rem"
          >
            <template #body="{ data }">
              <template v-if="data.imagen != null">
                <Image
                  :src="'data:image/png;base64, ' + data.imagen"
                  width="30"
                  preview
                ></Image>
              </template>
              <template v-else><p>---</p></template>
            </template>
          </Column>
          <Column expander header="MAS..." style="width: 2rem" />
          <template #expansion="slotProps">
            <div class="grid">
              <div class="col-3">
                <span class="font-weight-bold"><strong>GRUPO:</strong></span>
                {{ slotProps.data.grupo_nombre }}
              </div>
              <!-- <div class="col-3">
                <span class="font-weight-bold"><strong>CASO USO:</strong></span>
                {{ slotProps.data.caso_uso }}
              </div> -->
              <div class="col-3">
                <span class="font-weight-bold"
                  ><strong>UBICACIÓN:</strong></span
                >
                <span v-if="slotProps.data.stock.length > 0">
                  {{ slotProps.data.stock[0].ubicacion }}
                </span>
                <span v-else>---</span>
              </div>
              <div class="col-3">
                <span class="font-weight-bold"
                  ><strong>PRECIO FOB:</strong></span
                >
                <span v-if="slotProps.data.stock.length > 0">
                  {{ slotProps.data.stock[0].precio_cif }}
                </span>
                <span v-else>---</span>
              </div>

              <div class="col-3">
                <span class="font-weight-bold"
                  ><strong>PRECIO CIF:</strong></span
                >
                <span>
                  {{ slotProps.data.precio_compra ?? "-" }}
                </span>
              </div>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
    <template #footer>
      <div>
        <Button
          label="CANCELAR"
          icon="pi pi-times"
          class="p-button-danger p-button-lg"
          @click="cancelar"
        />
        <Button
          label="AÑADIR PRODUCTOS"
          icon="pi pi-shopping-cart"
          class="p-button-info p-button-lg"
          @click="agregarProductos"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { useAuth } from "@/stores";

export default {
  emits: ["closeModal", "agregarProductos"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productos: {
      type: Array,
      default() {
        return [];
      },
    },
    compra: {
      type: Boolean,
      default: false,
    },
    detalleProductos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_productos: this.productos,
      productos_selected: [],
      expandedRows: [],
      esCompra: this.compra,
      buscar: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  auth: null,
  created() {
    this.auth = useAuth();
  },

  methods: {
    claseEstado: (data) => {
      const cantidad = data.stock[0]?.cantidad ?? 0; // Obtener cantidad o 0 si no está definida

      if (cantidad <= 0) {
        return "outofstock";
      } else if (cantidad <= 10) {
        return "lowstock";
      } else {
        return "instock";
      }
    },
    esStockCero(rowData) {
      return rowData.stock[0]?.cantidad == 0;
    },
    obtenerPreciosVentaProductos(producto) {
      let tipo_cliente_id = producto.tipo_cliente_id ?? 1;
      let precio_sugerido = producto.precio_sugerido;
      if (tipo_cliente_id == 2) {
        precio_sugerido =
          producto.stock != null ? producto.stock[0].pv2 : precio_sugerido;
      } else if (tipo_cliente_id == 3) {
        precio_sugerido =
          producto.stock != null ? producto.stock[0].pv3 : precio_sugerido;
      }
      return parseFloat(precio_sugerido);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    stock_cero(rowData) {
      if (this.esStockCero(rowData)) {
        return "bg-blue-200 text-gray-500"; // Clase CSS para deshabilitar visualmente la celda
      }
      return "";
    },
    cancelar() {
      this.$emit("closeModal");
      this.productos_selected = [];
    },
    marcarTodos() {
      this.productos_selected = this.data_productos.map((item) => item.id);
    },
    agregarProductos() {
      this.$emit("agregarProductos", this.productos_selected);
    },
    /*  buscar_producto_del_detalle(){
      console.log('detalleProductos',this.detalleProductos);
      this.detalleProductos.forEach((item) => {
        //Busco el producto en el listado
        console.log('item',item);
        let producto = this.data_productos.find(
          (prod) => prod.id == item.id
        );
        //Ingresar a productos seleccionados
        this.productos_selected.push(producto);
      });
    } */
  },

  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    productos(value) {
      this.data_productos = value;
      //this.buscar_producto_del_detalle();
    },
  },
};
</script>
<style scoped>
.bg-blue-200 {
  background-color: #bfdbfe;
}

.text-gray-500 {
  color: #6b7280;
}
.text-red-500 {
  color: #f56565;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
